@use 'sass:map';

.travelPlanerResultsNewUI {
  --travelPlanerResultsHeaderHeight: 78px;

  width: 100%;
  background: $white;
  color: $black;

  &.withHeader {
    .travelPlanerResultsContent {
      margin-top: var(--travelPlanerResultsHeaderHeight);
    }
  }

  .leftHeader {
    background-color: $travelPlanerPartnerPrimary;
    color: white;
    padding: 12px;
    display: flex;
    height: 100%;

    .backButton {
      padding: 12px;

      svg {
        transform: rotate(0);

        path {
          stroke: white;
          fill: none;
        }
      }
    }

    .leftHeaderContent {
      display: flex;
      align-items: center;
      gap: 4px;
      flex-direction: column;

      .travelPlaceTitle {
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 22px;
        font-weight: 600;
        gap: 8px;

        @media screen and (min-width: 768px) {
          font-size: 42px;
          font-weight: 700;
        }
        .betaOutline {
          .Button {
            border: 1px solid $white;
            background: none;
            font-weight: 300;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
            padding: 6px 16px;
          }

          .popin {
            top: calc(100% + 6px);
            right: 0;
            left: auto;
            width: 182px;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
            animation: fadeIn 0.3s ease-in-out;

            .content {
              color: $travelPlanerPrimaryColor;
              padding: 0;
            }
          }
        }
      }
    }
  }

  .headerImage {
    width: 100%;
    object-fit: cover;
    filter: brightness(0.5);
    height: var(--travelPlanerResultsHeaderHeight);
    object-position: bottom;
  }

  .headerPlaceholder {
    width: 100%;
    height: var(--travelPlanerResultsHeaderHeight);
    background: $pro;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #e5e5e5;
    box-shadow: 0 10px 30px -30px #1b1f20;
    position: fixed;
    top: var(--travelPlanerResultsHeaderHeight);
    z-index: 1001;
    background: $white;
    width: 100%;
    max-height: 84px;

    .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      padding: 20px 24px;
      width: 100%;
      height: 100%;

      .Button {
        padding: 10px 24px;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        transition: all 0.2s ease-in-out;

        &:focus {
          outline: 1px solid $travelPlanerPrimaryColor;
        }
      }

      .left,
      .right {
        display: flex;
        align-items: center;
        gap: 6px;
        white-space: nowrap;
      }

      .left {
        flex: 1;
        max-width: calc(100% + 24px);

        .Button {
          text-transform: uppercase;
          background: $white;
          border-color: $travelPlanerPrimaryColor;
          color: $travelPlanerPrimaryColor;

          &.active,
          &:hover {
            background: $travelPlanerPrimaryColor;
            color: $white;

            &:focus {
              outline-width: 2px;
              outline-offset: 2px;
            }
          }
        }

        .days-container {
          flex: 1;
          display: flex;
          align-items: center;
          gap: 6px;
          overflow-x: auto;
          padding-right: 24px;
        }
      }

      .right {
        margin-left: auto;
      }

      .agent {
        .floatingButton {
          position: relative;
          height: 42px !important;
          right: 0;
          bottom: 0;
          padding: 2px 24px;
          background: $travelPlanerPrimaryColor !important;
          border: 1px solid $travelPlanerPrimaryColor;

          span {
            font-size: 12px;
            font-family: $travelPlanerSubtitleFont;
            font-weight: 700;
          }
        }

        @media screen and (min-width: 600px) {
          .agentBlock {
            bottom: 18px;
          }
        }
      }

      .PrintPDFButton {
        .pictoButton {
          padding: 11px;
          width: auto !important;
          height: auto !important;
          border-radius: 100px !important;
          background: $travelPlanerPrimaryColor !important;
          transition: all 0.2s ease-in-out;

          circle {
            display: none;
          }

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      .Share {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .Button {
          border-color: $travelPlanerPrimaryColor;
          background: $travelPlanerPrimaryColor;
          color: $white;
          transition: all 0.3s ease-in-out;
          font-family: $travelPlanerSubtitleFont;

          /*
          &.open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
          */

          &:focus {
            outline: none;
          }

          &:hover {
            svg {
              path {
                fill: $white;
                stroke: none;
              }
            }
          }
        }
      }
    }
  }

  .sharePopup,
  .feedbackPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    animation: fadeIn 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .sharePopupContainer,
    .feedbackPopupContainer {
      width: 100%;
      max-width: 400px;
      background: $white;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      border-radius: 5px;
      overflow: hidden;
      color: $travelPlanerPrimaryColor;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

      .sharePopupHeader,
      .feedbackPopupHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .container {
          display: flex;
          align-items: center;
          gap: 20px;

          svg {
            path {
              fill: $travelPlanerPrimaryColor;
            }
          }

          span {
            color: $travelPlanerPrimaryColor;
            font-family: $travelPlanerTitleFont;
            font-size: 20px;
            font-weight: 700;
          }
        }

        button {
          padding: 6px;
          border-radius: 48px;

          &:focus,
          &:active {
            svg {
              path {
                stroke: $white;
              }
            }
          }

          svg {
            path {
              stroke: $travelPlanerPrimaryColor !important;
            }
          }
        }
      }

      .feedbackDetails {
        font-family: $mainFont;
        border: 1px solid #dfdfdf;
        padding: 24px;
        resize: vertical;
        min-height: 65px;
        max-height: 400px;
      }

      .methods {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;

        a,
        button {
          padding: 10px 24px;
          border-radius: 5px;
          background: $travelPlanerButtonBackground;
          color: $travelPlanerButtonColor;
          border: 1px solid $travelPlanerButtonBackground;
          font-family: $mainFont;
          font-size: 12px;
          font-weight: 700;

          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }

  .head {
    display: flex;
    justify-content: space-between;

    button {
      color: $black;
      padding: 10px;
    }
  }

  .head {
    margin: 10px auto;
    max-width: 950px;
    padding: 0 10px;
    position: relative;
    width: 100%;

    .progressionBar {
      margin: 25px 40px;
      display: flex;
      gap: 5px;
      width: calc(100% - 80px);
      background: var(--gray50, #fafafa);
      padding: 10px;
      border-radius: 20px;

      div {
        flex: 1;
        height: 6px;
        background: $gray100;
        border-radius: 5px;
        background: $travelPlanerPrimaryColor;
        transition: all 0.2s linear;
      }
    }
  }

  &.fullScreen {
    margin-top: var(--travelPlanerResultsHeaderHeight);
    flex: 1;
    display: flex;
    flex-direction: column;

    .travelPlanerResultsContent {
      --col-gap: 0;
      flex: 1;
      //width: 100%;
      max-width: 100%;
      grid-template-columns: 100%;

      .travelDaysNight {
        grid-area: 3/1/4/2;
      }

      .travelDays {
        max-width: 100vw;
        padding: 0;
        padding-top: 12px;
        grid-area: 3/1/4/2;

        .travelDayLegInfo {
          margin-left: 24px;
          margin-right: 24px;
          max-width: calc(100% - 48px);
          margin-top: -6px;
        }

        .Restaurants {
          max-width: calc(100% - 48px);
        }
      }

      .days {
        box-shadow: 0px 2px 6px 0px #0000001a;
        padding: 24px;
        background-color: #fff;
        position: sticky;
        top: var(--travelPlanerResultsHeaderHeight);
        gap: 8px;
        overflow-x: auto;
        grid-area: 1/1/2/2;
        height: fit-content;
        width: 100%;
        z-index: 10;
        display: flex;
        align-items: center;
      }

      .travelCustomMap {
        margin: 0;
        min-height: calc(100dvh - var(--travelPlanerResultsHeaderHeight) - 64px);

        .directionProfileSelect {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 200;
          select {
            margin-top: 10px;
            margin-left: 12px;
          }
        }
        .travelMapContainer {
          max-height: calc(100dvh - var(--travelPlanerResultsHeaderHeight) - 64px);

          .travelPlanerMap {
            height: calc(100dvh - var(--travelPlanerResultsHeaderHeight) - 64px);
          }
          .travelPlanerMap,
          .leaflet-container {
            border-radius: 0;
          }
        }
      }
    }
  }

  .beta {
    color: #b6b6b6;
    font-family: $travelPlanerSubtitleFont;
    font-size: 12px;
    font-weight: 500;
  }

  .betaOutline {
    position: relative;

    .Button {
      padding: 4px 18px;
      border-color: $travelPlanerPrimaryColor;
      background: $travelPlanerPrimaryColor;
      color: $white;
      border-radius: 10px;
      font-family: $travelPlanerSubtitleFont;
      font-size: 15px;
      line-height: 18px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.15px;
    }

    .popin {
      position: absolute;
      background: $white;
      padding: 16px;
      z-index: 120;
      border-radius: 5px;
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
      font-size: 12px;
      font-weight: 300;
      top: 30px;
      max-width: calc(100vw - 48px);

      .title {
        color: $pro;
        font-weight: bold;
        margin-bottom: 8px;
      }

      .content {
        padding: 0;
      }
    }
  }

  .travelPlanerResultsContent {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto 1fr auto;
    min-height: 500px;
    max-width: 1500px;
    margin: auto;

    .content-wrapper {
      display: flex;
      flex-direction: column;

      .content {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .backButton {
          margin-right: auto;
        }

        .title {
          color: $travelPlanerPrimaryColor;
          font-family: $travelPlanerTitleFont;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px; /* 106.667% */
        }

        .description {
          color: $travelPlanerPrimaryColor;
          font-family: $travelPlanerSubtitleFont;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }

    .Restaurants {
      position: relative;
      max-width: 100%;

      .restaurants-header {
        padding: 0 24px 12px 24px;
        display: flex;
        align-items: center;
        gap: 8px;

        span {
          color: $travelPlanerPrimaryColor;
          font-family: $travelPlanerTitleFont;
          font-size: 22px;
          font-weight: 700;
          line-height: 32px;
        }
      }

      .restaurants-list {
        position: relative;
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 0 24px;
        overflow-x: auto;

        .Restaurant {
          min-width: 280px;
          min-height: 100%;
          border-radius: 6px;
          overflow: hidden;
          padding: 12px;
          background: #e5e5e5;
          display: flex;
          align-items: center;
          gap: 12px;
          align-self: stretch;

          img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            border-radius: 6px;
          }

          .restaurant-content {
            display: flex;
            flex-direction: column;
            gap: 10px;
            flex: 1;
            font-family: Ubuntu, sans-serif;

            .name {
              color: $travelPlanerPrimaryColor;
              font-size: 16px;
              font-weight: 700;
            }

            .informations {
              display: flex;
              flex-direction: column;

              span {
                color: #202020;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;

                &.openingHours {
                  font-weight: 700;
                }
              }
            }

            .icons {
              display: flex;
              align-items: center;
              gap: 4px;

              .imageContainer {
                height: 40px;

                &.quality {
                  height: 20px;
                }

                img {
                  height: 100%;
                  width: auto;
                }
              }
            }
          }
        }
      }
      @media screen and (min-width: 768px) {
        .restaurants-list {
          overflow-x: auto;
          padding: 0;
          padding-bottom: 4px;
        }
        .restaurants-list::-webkit-scrollbar:horizontal {
          display: unset;
          height: 8px;
        }
      }
    }

    .EditJourney {
      &.withLabel {
        .EditJourneyButton {
          padding: 8px 0;
          width: auto;
          height: auto;
          display: flex;
          align-items: center;
          gap: 10px;
          font-family: $travelPlanerSubtitleFont;
          font-size: 14px;
          line-height: 24px;
          font-weight: 800;
          color: $travelPlanerPrimaryColor;
          background: none;

          svg {
            width: 16px !important;
            height: 16px !important;
          }
        }

        @media screen and (min-width: 768px) {
          .EditJourneyButton {
            padding: 8px 10px;
          }
        }
      }

      .EditJourneyButton {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        cursor: pointer;
        background: $travelPlanerButtonBackground;
        border-radius: 100px;
        width: 38px;
        height: 38px;

        svg {
          width: 25px !important;
          height: 25px !important;
        }
      }

      .editJourneyPopup {
        position: fixed;
        z-index: 10000;
        width: 100vw;
        height: 100dvh;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        animation: fadeIn 0.3s ease-in-out forwards;

        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        &.dragging-day {
          .PopupDay {
            .popupDayContent {
              display: none;
            }
          }
        }

        span {
          font-size: 16px;
          font-weight: 500;
        }

        .editJourneyPopupContainer {
          display: flex;
          flex-direction: column;
          background: $white;
          max-height: 100dvh;
          width: 100%;

          .closeButtonContainer {
            width: 100%;
            padding: 12px;
            background: $white;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .closeButton {
              width: 40px;
              height: 40px;
              padding: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100px;
              transition: all 0.3s ease-in-out;

              &:hover {
                background: #efefef;
              }

              .closeButtonContent {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 3px;
                border-radius: 100px;
              }
            }
          }

          .editJourneyContentContainer {
            flex: 1;
            max-height: calc(100% - 62px - 96px);
            overflow: auto;

            &::-webkit-scrollbar {
              width: 4px;
            }

            &::-webkit-scrollbar-thumb {
              background: #a1a1a1;
              border-radius: 0;
            }

            .editJourneyPopupHeader {
              padding: 0 48px 24px 48px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .editJourneyPopupContent {
              display: flex;
              flex-direction: column;
              padding: 0 24px 96px 24px;
            }
          }

          .editJourneyPopupFooter {
            width: 100%;
            padding: 24px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 12px;
            background: $white;
            border-top: 1px solid #e5e5e5;

            .addStep {
              margin: 0 auto 0 0;
            }

            .applyButton,
            .resetButton {
              padding: 10px 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;
              border-radius: 5px;
              font-family: $mainFont;
              font-size: 12px;
              font-weight: 700;
              line-height: 1;
              background: var(--travelPlanerButtonBackground);
              color: var(--travelPlanerButtonColor);
              transition: all 0.2s ease-in-out;

              &:not(:disabled) {
                &:hover {
                  opacity: 0.9;
                }
              }

              &:disabled {
                background: #e5e5e5;
                color: #b6b6b6;
                cursor: default;
              }
            }

            .resetButton {
              animation: fadeIn 0.3s ease-in-out;
              padding: 10px;

              svg {
                width: 12px;
                height: 12px;

                path {
                  fill: $white;
                }
              }
            }
          }
        }

        .deleteButton {
          width: 32px;
          height: 32px;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          transition: all 0.3s ease-in-out;

          &:hover {
            background: #efefef;
          }

          .deleteButtonContent {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 3px;
            border-radius: 100px;
            border: 1px solid #cc0000;
          }

          svg {
            path {
              stroke: #cc0000;
            }
          }
        }

        .PopupDay {
          width: 100%;
          display: flex;
          flex-direction: column;
          background: $white;

          &.dragging {
            background: #f5f5f5;

            .popupDayHeader {
              opacity: 0.4;
            }

            .popupDayContent {
              display: none;
            }
          }

          &.overlay {
            overflow: hidden;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

            .popupDayHeader {
              .handle {
                cursor: grabbing;
              }
            }

            .popupDayContent {
              display: none;
            }
          }

          &.hovered {
            background: #f5f5f5;
          }

          .popupDayHeader {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 40px 0;
            border-bottom: 1px solid #e5e5e5;

            .popupDayHeaderNightItemImage {
              width: 56px;
              height: 56px;
              min-width: 56px;
              min-height: 56px;
              border-radius: 6px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }

              .credit {
                display: none;
              }
            }

            .popupDayHeaderContent {
              flex: 1;
              display: flex;
              flex-direction: column;
              gap: 4px;

              span {
                flex: 1;
              }

              .popupDayHeaderSubtitle {
                font-weight: 400;
              }

              .handle {
                cursor: grab;
              }
            }
          }

          .popupDayContent {
            display: flex;
            flex-direction: column;
          }
        }

        .PopupDayItem {
          padding: 24px 0;
          display: flex;
          align-items: center;
          gap: 12px;
          width: 100%;
          border-bottom: 1px solid #e5e5e5;
          cursor: grab;
          z-index: 1;

          &.dragging {
            background: #f5f5f5;
            z-index: 0;

            .deleteButton,
            .popupDayItemContent,
            > svg {
              opacity: 0.4;
            }
          }

          &.overlay {
            background: #e0e0e0;
            cursor: grabbing;
          }

          .popupDayItemContent {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 12px;

            .popupDayItemImage {
              width: 56px;
              height: 56px;
              min-width: 56px;
              min-height: 56px;
              border-radius: 6px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }

              .credit {
                display: none;
              }
            }
          }
        }

        @media screen and (min-width: 768px) {
          .editJourneyPopupContainer {
            height: 80dvh;
            width: 600px;
            border-radius: 12px;
            overflow: hidden;
          }
        }
      }
    }

    .days {
      /* z-index: 96; */
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 24px;
      width: 100%;
      background-color: #fff;
      box-sizing: border-box;
      position: relative;

      .days-container {
        display: flex;
        align-items: center;
        gap: 12px;
        overflow-x: auto;

        .skeleton {
          background: #e5e5e5;
          border: 1px solid #e5e5e5;
          color: #c8c8c8;
          cursor: default;
          pointer-events: none;
          animation: pulse 1.5s infinite;

          @keyframes pulse {
            0% {
              background: #e5e5e5;
              border: 1px solid #e5e5e5;
              color: #c8c8c8;
            }
            50% {
              background: #f5f5f5;
              border: 1px solid #f5f5f5;
              color: #e5e5e5;
            }
            100% {
              background: #e5e5e5;
              border: 1px solid #e5e5e5;
              color: #c8c8c8;
            }
          }
        }
      }

      button {
        text-align: center;
        padding: 8px;
        font-family: $travelPlanerSubtitleFont;
        font-size: 18px;
        font-style: italic;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.36px;
        background: $white;
        color: $travelPlanerPrimaryColor;
        border-radius: 10px;
        border: 1px solid $travelPlanerPrimaryColor;
        transition: all 0.3s ease-in-out;

        &.selected {
          color: $white;
          background: $travelPlanerPrimaryColor;
        }
      }

      .agent {
        .floatingButton {
          position: relative;
          height: 38px !important;
          right: 0;
          bottom: 0;
          padding: 8px 16px;
          background: $travelPlanerSecondaryColor !important;
          border: 1px solid $travelPlanerSecondaryColor;
          gap: 4px;

          svg {
            width: 14px;
          }

          span {
            font-size: 12px;
            font-family: $travelPlanerSubtitleFont;
            font-weight: 700;
            white-space: nowrap;
          }
        }

        .close {
          border: none;
          background: none;
          padding: 4px;
          width: auto;
          min-width: unset;
        }

        @media screen and (min-width: 600px) {
          .agentBlock {
            bottom: 18px;
          }
        }

        @media screen and (min-width: 768px) {
          .floatingButton {
            height: 38px !important;
            padding: 2px 24px;
            gap: 6px;

            svg {
              width: 18px;
            }

            span {
              font-size: 14px;
            }
          }
        }
      }
    }

    .travelCustomMap {
      display: flex;
      flex-direction: column;
      gap: 44px;

      .directionProfileSelect {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 12px;
        padding: 0 24px;
        color: $travelPlanerPrimaryColor;

        label {
          font-size: 14px;
          font-weight: 400;
          text-align: right;
        }

        select {
          margin-left: 10px;
          padding: 6px 12px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 700;
          background-color: #ececec;
        }
      }

      .travelPlanerMap {
        width: 100%;
        height: 454px;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        opacity: 0;
        animation: fadeIn 1s ease-in-out forwards;

        &.hideAttribution {
          .leaflet-control-attribution {
            display: none;
          }
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        .leaflet-container {
          position: relative;
          z-index: 0;
          flex: 1;

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.8);
          }

          .leaflet-div-icon {
            background: none;
            border: none;
          }

          .leaflet-marker-icon {
            &.focused {
              z-index: 10000 !important;
            }
          }

          .leaflet-control-zoom {
            flex-direction: column;
            align-items: center;
            gap: 6px;
            border: none;
            display: none;

            .leaflet-control-zoom-in,
            .leaflet-control-zoom-out {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0;
              font-size: 30px;
              width: 24px;
              height: 24px;
              font-weight: 400;
              color: $travelPlanerSecondaryColor;
            }

            .leaflet-disabled {
              background: gray;
            }

            span {
              margin-top: -5px;
              margin-left: -1px;
            }
          }

          .searchInThisArea {
            position: absolute;
            z-index: 1000;
            bottom: 64px;
            left: 50%;
            translate: -50% 0;
            background: $travelPlanerPrimaryColor;
            color: $white;
            border: none;
            padding: 10px 25px;
            white-space: nowrap;

            svg {
              width: 16px;
              height: 16px;

              path {
                fill: $white;
              }
            }
          }
        }

        .travelPlanerMap__mapButtons {
          margin: 12px 16px;
          width: calc(100% - 32px);
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          flex-wrap: wrap;
        }

        .mapLink {
          background: $travelPlanerPrimaryColor;
          color: $white;
          padding: 10px 24px;
          border-radius: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 12px;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0.01em;
          cursor: pointer;

          svg {
            path {
              fill: $white;
            }
          }
        }

        .mapButton {
          position: absolute;
          bottom: 0;
          left: 0;
          width: auto;
          margin: 10px;
          button {
            font-size: 1rem;
            text-align: center;
            padding: 8px;
            font-family: var(--travelPlanerSubtitleFont, Arial, sans-serif);
            font-style: italic;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.36px;
            white-space: nowrap;
          }
        }

        .days {
          display: flex;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          overflow-x: auto;
          padding: 12px;
          height: fit-content;
          margin-top: auto;
          background: transparent;
          box-shadow: none;
        }
      }

      .customLink {
        margin: 0 12px;
        width: calc(100% - 96px);
        position: fixed;
        bottom: 12px;
        display: flex;
        align-items: center;
        gap: 12px;
        height: 58px;
        background: $white;
        border-radius: 8px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
        overflow: hidden;
        z-index: 1000;

        img {
          height: 100%;
          width: 35%;
          object-fit: cover;
        }

        span {
          color: $travelPlanerPrimaryColor;
          font-family: $travelPlanerTitleFont;
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
          flex: 1;
        }

        .arrowRight {
          padding: 12px;
          height: 100%;
          background: $travelPlanerPrimaryColor;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            path {
              fill: $white;
            }
          }
        }
      }
    }

    .travelDays {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      max-width: 600px;
      padding: 12px 24px 24px 24px;

      .travelPoints {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        width: 100%;
        position: relative;
      }

      .addStep {
        margin: -60px auto 0 24px;
        padding: 0;
        color: $travelPlanerButtonBackground;
        background: none;
        border-radius: 100px;
        font-size: 12px;
        font-weight: 700;
        border: none;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        &:hover {
          opacity: 0.9;
        }

        .iconWrapper {
          padding: 8px;
          background: #f4f1e8;
          border-radius: 100px;
        }

        svg {
          width: 14px;
          height: 14px;

          path {
            stroke: $travelPlanerButtonBackground;
          }
        }
      }

      .dayHeader {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;

        .title {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .editButton {
          display: flex;
          align-items: center;
          justify-content: center;
          background: $travelPlanerButtonBackground;
          color: $travelPlanerButtonColor;
          padding: 10px 24px;
          border-radius: 5px;
          white-space: nowrap;
          font-size: 12px;
          font-weight: 700;
          border: none;

          &:hover {
            opacity: 0.9;
          }

          svg {
            width: 14px;
            height: 14px;
          }
        }
      }

      .travelPoint {
        display: flex;
        width: 100%;

        .indexLine {
          margin-right: 12px;
          position: relative;
          display: flex;
          flex-direction: column;

          span {
            color: $travelPlanerPrimaryColor;
            font-family: $travelPlanerTitleFont;
            font-size: 50px;
            line-height: 1;
          }

          .line {
            $dashSize: 10px;
            $dashSpace: 10px;
            $dashWidth: 2px;
            flex: 1;
            background-image: repeating-linear-gradient(
              0deg,
              $travelPlanerPrimaryColor,
              $travelPlanerPrimaryColor $dashSize,
              transparent $dashSize,
              transparent $dashSize + $dashSpace,
              $travelPlanerPrimaryColor $dashSize + $dashSpace
            );
            background-size:
              $dashWidth 100%,
              100% $dashWidth,
              $dashWidth 100%,
              100% $dashWidth;
            background-position:
              0 0,
              0 0,
              100% 0,
              0 100%;
            background-repeat: no-repeat;
          }
        }

        .content {
          flex: 1;
          overflow: hidden;
          position: relative;
          padding-right: 16px;

          .name {
            color: $travelPlanerPrimaryColor;
            font-family: $travelPlanerTitleFont;
            font-size: 24px;
            line-height: 26px;
            font-weight: 700;
            letter-spacing: 0.3px;
            margin: 12px 0;
          }

          .description {
            color: $travelPlanerPrimaryColor;
            font-family: $travelPlanerSubtitleFont;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
          }

          .blocks {
            margin: 8px 0;
            align-self: flex-start;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            flex-wrap: wrap;

            .blockContainer {
              padding: 4px 8px;
              border-radius: 50px;
              display: flex;
              align-items: center;
              gap: 4px;
              background: var(--client);
              color: #ffffff;

              svg {
                width: 16px;
                height: 16px;

                path {
                  fill: #ffffff;
                }

                circle,
                rect {
                  stroke: #ffffff;
                }
              }
            }
          }

          .imageWrapper {
            position: relative;

            img {
              object-fit: cover;
              width: 100%;
              height: 194px;
              border-radius: 10px;
            }

            .time {
              position: absolute;
              top: 10px;
              left: 10px;
              border-radius: 7px;
              background: $travelPlanerPrimaryColor;
              color: $white;
              padding: 6px 12px;
              font-size: 15px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 0.15px;
            }
          }

          .contentBody {
            padding: 12px 0;
            display: flex;
            align-items: flex-start;
            gap: 6px;
            flex-wrap: wrap;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            white-space: nowrap;

            .openSunday {
              background: $travelPlanerPrimaryColor;
              color: $white;
              padding: 2px 4px;
              border-radius: 4px;
            }

            .link {
              text-decoration-line: underline;
            }

            .openSunday,
            .link {
              margin-right: 20px;
            }

            .rate {
              display: flex;
              margin-right: 10px;
              font-weight: 400;
              font-size: 16px;

              .starNote {
                .star {
                  width: 12px;
                  height: 14px;
                }
              }
            }

            a,
            button {
              letter-spacing: 0.105px;
              display: flex;
              align-items: center;
              gap: 8px;
              cursor: pointer;
              font-weight: 600;

              svg {
                path {
                  stroke: currentColor;
                }
              }
            }

            .navigation {
              width: 100%;

              button {
                font-size: 14px;
                line-height: 20px;

                svg {
                  transition: all 0.3s ease-in-out;
                }
              }

              .navigationPopup {
                padding: 10px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;

                .items {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  gap: 8px;

                  a {
                    width: 100%;

                    div {
                      display: flex;
                      align-items: center;
                      gap: 8px;
                      flex: 1;
                      animation: slideRight 0.3s ease-in-out;

                      @keyframes slideRight {
                        from {
                          transform: translateX(-10px);
                          opacity: 0;
                        }
                        to {
                          transform: translateX(0);
                          opacity: 1;
                        }
                      }

                      img {
                        width: 30px;
                        height: 30px;
                      }
                    }
                  }
                }

                button {
                  font-family: $travelPlanerSubtitleFont;
                }
              }

              &.open {
                & > a {
                  svg {
                    rotate: 90deg;
                  }
                }
              }
            }
          }

          .activitiesSwitch {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            width: 100%;
            padding: 6px;
            color: $travelPlanerPrimaryColor;
            background: #e5e5e5;
            border-radius: 6px;

            button {
              padding: 10px;
              border-radius: 6px;
              background: $travelPlanerPrimaryColor;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30px;
              height: 30px;

              svg {
                path {
                  stroke: $white;
                }
              }
            }

            span {
              font-weight: 600;
              font-size: 14px;
              font-family: $travelPlanerSubtitleFont;
            }
          }
        }
      }

      .aiMention {
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        margin-top: 16px;

        .aiMentionTitle {
          font-family: Ubuntu;
          font-size: 14px;
          font-weight: 500;
          color: #cccbcb;
        }
      }
    }

    .daysFooter {
      z-index: 96;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 25px 32px;
      grid-area: 4/1/5/2;
      box-shadow: 0 -2px 6px 0 #0000001a;

      .daysFooterButtons {
        display: flex;
        flex-direction: row;
        gap: 6px;
      }

      .showMap {
        display: none;
      }

      .pictoButton.shareButton {
        svg {
          width: 20px !important;
          height: 20px !important;
        }
      }

      .pictoButton {
        background: var(--travelPlanerButtonBackground);
        width: 38px !important;
        height: 38px !important;
        border-radius: 25px !important;
        svg {
          width: 22px;
          height: 22px;
          path {
            stroke: white;
          }
        }
        &:nth-child(2) {
          svg {
            width: 30px;
            height: 30px;
            circle {
              display: none;
            }
            path {
              fill: none;
            }
          }
        }
        &:nth-child(3) {
          svg {
            width: 20px;
            height: 20px;
            path {
              fill: none;
            }
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      .daysFooter {
        padding: 12px 24px;
        gap: 10px;

        .showMap {
          flex: 1;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 8px;
          border-radius: 5px;
          padding: 10px 24px;
          background: $travelPlanerButtonBackground;
          transition: all 0.2s ease-in-out;

          &:hover {
            opacity: 0.9;
          }

          svg {
            height: auto;
            width: 14px;

            path {
              fill: $travelPlanerButtonColor;
            }
            circle {
              fill: $travelPlanerButtonColor;
            }
          }

          span {
            color: $travelPlanerButtonColor;
            font-size: 12px;
            font-weight: 700;
          }
        }
      }
    }
    .sitesExplorator {
      display: flex;
      flex-direction: column;
      min-height: calc(100dvh - var(--travelPlanerResultsHeaderHeight));
      max-height: calc(100dvh - var(--travelPlanerResultsHeaderHeight));
      overflow: auto;

      // Scrollbar, remove the radius
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--travelPlanerPrimaryColor);
        border-radius: 0;
      }

      &::-webkit-scrollbar-track {
        background-color: #fafafa;

        &:hover {
          background-color: #f0f0f0;
        }
      }

      .backButton {
        z-index: 1000;
        box-shadow: 0 2px 6px 0 #0000001a;
        grid-area: 1/1/1/1;
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 32px 24px;
        height: fit-content;
        font-weight: 500;
        font-size: 14px;
        color: var(--travelPlanerSecondaryColor);
        cursor: pointer;
        background-color: var(--white);

        svg {
          transform: rotate(270deg);

          path {
            fill: var(--travelPlanerSecondaryColor);
          }
        }
      }

      .container {
        position: relative;
        flex: 1;
        grid-area: 2/1/2/1;
        padding: 32px 38px;

        .controls {
          border-bottom: solid 1px #ebebeb;

          .title {
            font-size: 32px;
            font-weight: 700;
          }

          .inputBlock {
            position: relative;
            margin: 8px 0;
            max-width: 100%;
            padding: 8px 0;
            width: 100%;
            height: 50px;

            input {
              background: $white;
              color: $black;
              width: 100%;
              border: solid 1px $black;
              height: 41px;
              padding: 24px 24px 24px 48px;
              font-family: var(--mainFont);
              border-radius: 5px;
              border-color: #dfdfdf;
              font-weight: 500;
              font-size: 12px;
            }

            svg {
              position: absolute;
              left: 24px;
              top: 26px;
            }
          }

          .Toggle {
            margin: 24px auto 0 auto;
            border: 1px solid $travelPlanerSecondaryColor;

            .toggle-inner {
              background: $travelPlanerSecondaryColor;
            }

            .toggle-item {
              color: $travelPlanerSecondaryColor;

              &.selected {
                color: $white;
              }
            }
          }

          .hideAndSort {
            margin: 32px 0 16px auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            flex-wrap: wrap;
            font-weight: 400;
            font-size: 12px;

            .hideCheckboxContainer {
              display: flex;
              gap: 8px;
              color: #6d6d6d;

              input {
                border-color: #6d6d6d;
              }
            }

            .sortContainer {
              display: flex;
              align-items: center;
              gap: 8px;

              & > div {
                white-space: nowrap;
              }

              select {
                cursor: pointer;
                font-weight: 500;
                font-family: $travelPlanerTitleFont;
              }
            }
          }
        }

        .filtersContainer {
          .filters {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 6px;
            padding: 24px 0 16px 0;

            .filter {
              padding: 10px 15px;
              font-size: 14px;
              font-weight: 500;
              line-height: 16px;
              letter-spacing: -0.36px;
              white-space: nowrap;
              min-width: auto;
              background: $white;
              color: $travelPlanerSecondaryColor;
              border: 1px solid $travelPlanerSecondaryColor;
              transition: all 0.3s ease-in-out;

              &.selected {
                background: $travelPlanerSecondaryColor;
                color: $white;
              }
            }

            .disabilitiesFilter {
              padding: 0;
              border-radius: 0;

              &.selected {
                border: 1px solid $travelPlanerSecondaryColor;
              }

              img {
                width: 40px;
                height: 40px;
              }
            }
          }
        }

        .results {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
          gap: 8px;
          margin: 16px 0;

          .site {
            cursor: pointer;
            position: relative;
            display: flex;
            flex-direction: column;
            transition: all 0.2s ease-in-out;
            text-align: left;
            padding: 8px;

            &.focused {
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
              border-radius: 6px;
              transform: scale(1.05);
            }

            .imageWrapper {
              width: 100%;
              height: 125px;
              position: relative;

              svg {
                top: 8px;
                right: 8px;
                position: absolute;
                background: var(--travelPlanerPrimaryColor);
                border-radius: 50%;
                padding: 4px;
                width: 20px;
                height: 20px;
                path {
                  fill: var(--white);
                }
              }

              img {
                position: absolute;
                top: 0;
                border-radius: 5px;
                width: 100%;
                height: 125px;
                object-fit: cover;
              }
            }

            .bodyContainer {
              width: 100%;
              flex: 1;
              display: flex;
              flex-direction: column;

              .name {
                width: 100%;
                font-size: 16px;
                font-family: $travelPlanerTitleFont;
                font-weight: 500;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
                margin: 8px 0 2px 0;
                display: flex;
                align-items: center;
                gap: 4px;
                flex-flow: row wrap;
              }

              .gradeContainer {
                display: flex;
                height: 18px;
                margin-bottom: 8px;

                .grade {
                  margin-right: 8px;
                  font-size: 14px;
                  font-weight: 400;
                  color: var(--travelPlanerBackgroundColor);
                }

                .starNote {
                  margin-top: 3px;

                  .star {
                    width: 15px;
                    height: 15px;
                  }
                }
              }

              .tourismeEtHandicap {
                display: flex;
                align-items: center;
                gap: 4px;

                .assignedDisabilities {
                  padding: 8px 0;
                  max-height: 60px;
                  box-sizing: content-box;

                  img {
                    width: auto;
                    height: 100%;
                    object-fit: contain;
                    margin-right: auto;
                  }

                  &.quality {
                    max-height: 20px;
                  }
                }
              }

              .description {
                font-size: 14px;
                font-weight: 300;
                color: #6d6d6d;
                height: 60px;
                line-height: 20px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
                margin-bottom: 8px;
              }

              .tags {
                width: fit-content;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                margin: 8px 0;

                .tag {
                  width: fit-content;
                  font-size: 10px;
                  font-weight: 700;
                  padding: 6px 12px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 4px;
                  background: $black;
                  color: $white;
                  border-radius: 100px;
                }
              }

              .selectDate {
                margin-top: auto;
                width: 100%;
                background: var(--travelPlanerLightOrange);
                padding: 8px 12px;
                border-radius: 100px;
                display: flex;
                align-items: center;

                &:hover {
                  cursor: pointer;

                  * {
                    cursor: pointer;
                  }
                }

                .date {
                  width: 100%;
                  display: flex;
                  color: var(--beige);
                  font-size: 12px;
                  font-weight: 500;
                  font-family: $travelPlanerTitleFont;
                  margin-left: 6px;

                  select {
                    flex: 1;
                    font-weight: 500;
                    font-family: $travelPlanerTitleFont;
                  }
                }

                svg:last-child {
                  transform: rotate(180deg);

                  path {
                    fill: $travelPlanerSecondaryColor;
                  }
                }
              }

              .selectDate.notInTrip {
                background-color: var(--white);
                border: 1px solid #cccbcb;

                .date {
                  color: #6d6d6d;
                }

                path {
                  fill: #6d6d6d;
                }
              }
            }
          }
        }

        .sitesPagination {
          display: flex;
          align-items: center;
          justify-content: center;

          .loadMoreButton {
            transition: all 0.2s ease-in-out;
            padding: 12px 24px;
            gap: 8px;
            background: $travelPlanerSecondaryColor;

            &:hover {
              color: $travelPlanerSecondaryColor;
              background: $white;
              border: 1px solid $travelPlanerSecondaryColor;

              svg {
                path {
                  stroke: $travelPlanerSecondaryColor;
                }
              }
            }

            svg {
              width: 12px;
              height: 12px;

              path {
                transition: all 0.2s ease-in-out;
                stroke: $white;
              }
            }
          }
        }
      }

      .footer {
        display: none;
      }
    }
  }

  .pictoButton {
    background: var(--travelPlanerSecondaryColor);
    width: 14px;
    height: 14px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: 768px) {
    --travelPlanerResultsHeaderHeight: 100px;

    &.withHeader {
      .travelPlanerResultsContent {
        .travelCustomMap {
          .travelMapContainer {
            top: calc(2 * var(--travelPlanerResultsHeaderHeight) + 24px);
          }
        }
      }
    }

    &.fullScreen {
      .travelPlanerResultsContent {
        grid-template-columns: 50% 50%;
        .travelDays {
          padding: 12px 24px 24px 24px;
          max-width: 50%;
          grid-area: 3/1/4/3;
          gap: 24px;
        }
        .days {
          grid-area: unset;
          width: 100%;
          box-sizing: border-box;
          display: flex;
          align-items: center;
        }
        .travelCustomMap {
          .travelMapContainer {
            top: var(--headerHeight);
            flex: 1;
            max-height: calc(100dvh - var(--headerHeight));
            grid-area: 1/2/5/3;
            margin-bottom: 0;
          }
          .travelPlanerMap {
            height: 100%;
            flex: 1;
          }
        }
      }
    }

    .header {
      .header-container {
        .left {
          .days-container {
            padding-right: 0;
          }
        }
      }
    }

    .travelPlanerResultsContent {
      --col-gap: 64px;
      grid-template-columns: calc(50% - var(--col-gap) / 2) calc(50% - var(--col-gap) / 2);
      column-gap: var(--col-gap);

      .content-wrapper {
        grid-area: 1 / 1 / 2 / 2;

        .leftHeader {
          padding: 24px;
          display: flex;
          flex-direction: column;

          .leftHeaderContent {
            padding: 12px;
            margin: auto;

            .betaOutline {
              .Button {
                padding: 8px 16px;
              }
            }
          }
        }

        .content {
          .title {
            font-size: 52px;
            line-height: 52px;
            margin-top: 36px;
          }
        }
      }

      .days {
        grid-area: 2 / 1 / 3 / 2;
        width: 100%;
        box-sizing: border-box;
        max-width: 100%;
      }

      .travelCustomMap {
        position: relative;
        grid-area: 1 / 2 / 5 / 3;
        margin-top: 48px;
        margin-right: 24px;

        .directionProfileSelect {
          padding: 0;
        }

        .travelPlanerMap {
          border-radius: 8px;

          .leaflet-container {
            border-radius: 8px;
          }

          .travelPlanerMap__mapButtons {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 10px;
            width: auto;
          }

          .days {
            width: fit-content;
            top: 0;
            right: 0;
            bottom: auto;
            left: auto;
            margin-left: auto;
          }
        }

        .travelMapContainer {
          position: sticky;
          top: 24px;
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin-bottom: 24px;

          .customLink {
            position: relative;
            width: 100%;
            margin: 0;
            bottom: 0;
          }
        }
      }
      .travelDaysNight {
        grid-area: 3 / 1 / 4 / 2;
      }
      .travelDays {
        grid-area: 3 / 1 / 4 / 2;

        .dayHeader {
          padding: 0;
        }

        .addStep {
          margin: -12px auto 0 4px;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .travelPlanerResultsContent {
      --col-gap: 124px;
      .fullScreenTravelPoint {
        padding: 8px;
      }
    }
  }

  .travelPlanerResultsContent {
    .resultsHeader {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin: 0 50px;

      .headerTop {
        display: flex;
        flex-direction: column;

        .backButton {
          padding: 16px 0;
          width: 17px;
        }

        .search {
          color: $travelPlanerSecondaryColor;
          font-family: $travelPlanerSubtitleFont;
          font-size: 18px;
          line-height: 20px;
          font-weight: 600;
          letter-spacing: 0.18px;
          display: flex;
          align-items: center;
          gap: 16px;
          position: relative;
        }

        .dayNumber {
          color: $travelPlanerSecondaryColor;
          font-family: $travelPlanerSubtitleFont;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 14px;
          letter-spacing: 0.12px;
        }
      }

      .headerBottom {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .shareBlock {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .Button {
            border-color: $travelPlanerPrimaryColor;
            background: $travelPlanerPrimaryColor;
            color: $white;
            transition: all 0.3s ease-in-out;
            font-family: $travelPlanerSubtitleFont;

            &:focus,
            &:active {
              background: $travelPlanerPrimaryColor;
              color: $white;
            }

            &.open {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }
          }

          .sharePopup {
            position: absolute;
            right: 0;
            top: 37px;
            background: $white;
            padding: 5px;
            border-radius: 0 0 10px 10px;
            border: solid 1px $travelPlanerPrimaryColor;
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: center;
            width: 100%;
            z-index: 1;
            animation: maxHeight 0.3s ease-in-out;
            overflow: hidden;

            @keyframes maxHeight {
              0% {
                max-height: 0;
              }
              100% {
                max-height: 150px;
              }
            }

            a,
            button {
              font-size: 12px;
              padding: 5px;
              font-family: $travelPlanerSubtitleFont;
            }

            a {
              font-weight: 500;
            }

            button:active {
              background: $travelPlanerPrimaryColor;
              color: white;
              border-radius: 5px;
            }
          }
        }
      }
    }

    .days {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 18px;
      padding: 10px 50px 20px;
      overflow: hidden;

      button {
        text-align: center;
        padding: 8px;
        font-family: $travelPlanerSubtitleFont;
        font-size: 18px;
        font-style: italic;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.36px;
        white-space: nowrap;
        background: $white;
        color: $travelPlanerPrimaryColor;
        border-radius: 10px;
        border: 1px solid $travelPlanerPrimaryColor;
        transition: all 0.3s ease-in-out;

        &.selected {
          color: $white;
          background: $travelPlanerPrimaryColor;
        }
      }
    }
    .travelDays {
      display: flex;
      flex-direction: column;
      gap: 44px;

      .travelPoints {
        display: flex;
        flex-direction: column;
        gap: 0;
      }

      .travelDayLegInfo {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 39px;
        padding: 12px 13px;
        background-color: #e5e5e5;
        border-radius: 6px;
        font-size: 14px;
        align-items: center;
        color: $travelPlanerPrimaryColor;

        path {
          fill: $travelPlanerPrimaryColor;
        }

        .duration {
          display: flex;
          flex-direction: row;
          gap: 4px;
          align-items: center;

          .value {
            font-weight: 700;
          }
        }

        .distance {
          height: 42px;
          display: flex;
          flex-direction: column;
          gap: 3px;
          align-items: center;
          font-weight: 700;
          justify-content: center;
        }
      }

      .travelDayPoints {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 28px;
        max-width: 450px;
        padding: 0 16px 50px 16px;
      }

      .travelPoint {
        .indexLine {
          margin-right: 16px;
        }

        .content {
          .name {
            font-size: 30px;
            line-height: 1;
            margin-bottom: 20px;
          }

          .imageWrapper {
            img {
              height: 300px;
            }
          }
        }
      }

      .fullScreenTravelPoint {
        display: flex;
        width: 100%;

        .indexLine {
          height: 370%;
          margin-right: 16px;
          position: relative;
          display: flex;
          flex-direction: column;

          span {
            font-family: 'Ubuntu';
            font-size: 16px;
            font-weight: 700;
            background: var(--beige);
            color: var(--white);
            width: 36px;
            height: 36px;
            border-radius: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .line {
            background-image: repeating-linear-gradient(
              0deg,
              var(--beige),
              var(--beige) 1px,
              transparent 5px,
              transparent 1px,
              var(--beige) 1px
            );
            margin-left: 17px;
            flex: 1 1;
            background-size:
              2px 100%,
              100% 2px,
              2px 100%,
              100% 2px;
            background-position:
              0 0,
              0 0,
              100% 0,
              0 100%;
            background-repeat: no-repeat;
          }
        }

        .indexLine,
        .indexLine.last,
        .indexLine.open.last {
          height: 100%;
        }

        .content {
          display: flex;
          flex-direction: column;
          padding: 0;
          flex: 1 1;
          overflow: hidden;
          position: relative;
          background: $white;
          transition:
            rotate 0.2s linear,
            background 0.2s linear,
            box-shadow 0.1s linear,
            scale 0.2s linear;

          .contentHeader {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            margin-top: 4px;

            .titleContainer {
              margin-top: 4px;
              flex: 1;

              .name {
                display: flex;
                align-items: center;
                flex-flow: row wrap;
                gap: 10px;
              }

              .tourismeEtHandicap {
                display: flex;
                align-items: center;
                gap: 4px;
              }
            }

            .title {
              display: flex;
              align-items: center;
              gap: 10px;
              cursor: pointer;
            }
          }

          div.contentHeader > div > div > svg {
            height: 15px;
            width: 15px;
          }

          div.contentHeader.open > div > div > svg {
            transform: rotate(180deg);
          }

          div.contentHeader:not(.open) > div > div > svg {
            transform: rotate(90deg);
          }

          .separation {
            background: var(--travelPlanerBackgroundColor);
            height: 2px;
            margin-top: 16px;
            margin-left: 40px;
          }

          .name {
            display: flex;
            font-size: 20px;
            font-weight: 500;
            margin: 0;
            letter-spacing: 0.3px;
            color: var(--travelPlanerPrimaryColor);
          }

          .assignedDisabilities {
            img {
              width: auto;
              height: 40px;
              object-fit: contain;
            }
          }

          .contentBody {
            height: 0;
            overflow: hidden;
            transition: height ease 0.5s;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            flex-wrap: wrap;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            white-space: nowrap;

            .link {
              color: var(--travelPlanerSecondaryColor);
              align-self: flex-start;
              padding-left: 40px;
              font-size: 14px;
              font-weight: 500;
              text-decoration: underline;

              svg {
                display: none;
              }
            }

            .navigation {
              width: 100%;
              padding-left: 40px;
              margin-top: 8px;

              & > button {
                display: flex;
                align-items: center;
                gap: 8px;
                font-weight: 700;
              }

              .navigationPopup {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;

                .items {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  padding: 8px;

                  a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    div {
                      display: flex;
                      align-items: center;
                      gap: 8px;

                      img {
                        width: 28px;
                        height: 28px;
                      }
                    }
                  }
                }
              }
            }

            .imageWrapper {
              margin-top: 16px;
              width: 100%;
              padding-left: 40px;

              img {
                border-radius: 5px;
                object-fit: cover;
                width: 100%;
                height: 300px;
              }
            }

            .description {
              align-self: flex-start;
              white-space: break-spaces;
              padding-left: 40px;
              font-size: 16px;
              font-weight: 400;
              line-height: 22px;
            }

            .blocks {
              padding-left: 40px;
              margin: 8px 0;
              align-self: flex-start;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 8px;
              flex-wrap: wrap;

              .blockContainer {
                padding: 4px 8px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                gap: 4px;
                background: var(--client);
                color: #ffffff;

                svg {
                  width: 16px;
                  height: 16px;

                  path {
                    fill: #ffffff;
                  }

                  circle,
                  rect {
                    stroke: #ffffff;
                  }
                }
              }
            }
          }

          .contentBody.open {
            height: auto;
            transition: height ease 0.5s;
          }
        }

        @media screen and (max-width: 768px) {
          padding: 24px;

          .indexLine {
            margin-right: 4px;

            span {
              width: 30px;
              height: 30px;
            }

            .line {
              margin-left: 14px;
            }
          }

          .content {
            .contentHeader {
              .title {
                .name {
                  display: flex;
                  align-items: center;
                  flex-flow: row wrap;
                  gap: 8px;
                  font-size: 16px;
                }

                svg:first-child {
                  margin: 6px 8px 6px 4px;
                  width: 8px;
                }
              }

              svg:first-child {
                margin: 4px 4px 0 4px;
              }
            }

            .contentBody {
              align-items: flex-start;
              margin-left: 20px;
              opacity: 0;
              gap: 8px;

              &.open {
                animation: fade-in 0.5s ease-in-out forwards;

                @keyframes fade-in {
                  from {
                    opacity: 0;
                    scale: 0.9;
                  }
                  to {
                    opacity: 1;
                    scale: 1;
                  }
                }
              }

              .imageWrapper {
                height: 175px;
                margin-top: 4px;
                padding: 0;

                img {
                  height: 100%;
                }
              }
              .description {
                padding: 0;
                font-size: 14px;
                line-height: 18px;
              }
              .blocks {
                padding: 0;
              }
              .link {
                padding: 0;
                text-decoration: none;
              }
              .navigation {
                padding-left: 0;
              }
            }
          }
        }
      }

      .travelPlanerMap {
        width: 100%;
        height: 454px;
        overflow: hidden;

        .leaflet-container {
          height: 100%;
          position: relative;
          z-index: 0;

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.8);
          }

          .leaflet-div-icon {
            background: none;
            border: none;
          }

          .leaflet-control-zoom {
            flex-direction: column;
            align-items: center;
            gap: 6px;
            border: none;
            display: none;

            .leaflet-control-zoom-in,
            .leaflet-control-zoom-out {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0;
              font-size: 30px;
              width: 24px;
              height: 24px;
              font-weight: 400;
              color: $travelPlanerSecondaryColor;
            }

            .leaflet-disabled {
              background: gray;
            }

            span {
              margin-top: -5px;
              margin-left: -1px;
            }
          }

          .leaflet-control-attribution {
            display: none;
          }
        }
      }
    }

    @media screen and (min-width: 768px) {
      .resultsHeader {
        margin: 0;
        flex-direction: row;
        justify-content: space-between;
        gap: 58px;

        .headerTop {
          flex-direction: row;
          gap: 42px;

          .backButton {
            padding: 8px 0;
          }
        }

        .headerBottom {
          justify-content: space-between;
        }
      }

      .travelDayWithMap {
        flex-direction: row-reverse;
        gap: 64px;

        .travelDayPoints {
          min-width: calc(50% - 32px);
          padding: 0 0 50px 0;
          gap: 44px;
        }

        .travelPoint {
          .indexLine {
            margin-right: 32px;
          }

          .content {
            padding-right: 0;

            .imageWrapper {
              img {
                height: 263px;
              }

              .time {
                top: 25px;
                left: 25px;
              }
            }

            .contentBody {
              padding: 10px 0;
              flex-wrap: nowrap;
            }
          }
        }

        .travelPlanerMap {
          height: 590px;
          padding-top: 50px;
          position: sticky;
          top: 0;
          margin-bottom: 64px;

          .leaflet-container {
            .leaflet-control-zoom {
              display: flex;
              margin-top: 20px;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }

  .review {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin: 24px auto;
    padding: 0 20px;

    .thumbs {
      display: flex;
      align-items: center;
      gap: 8px;

      button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2px;
        color: $travelPlanerPrimaryColor;
        font-family: $travelPlanerSubtitleFont;
        font-size: 10px;
        line-height: 2;
        font-weight: 500;

        svg {
          height: 30px;
          width: 30px;

          path {
            fill: $travelPlanerPrimaryColor;
          }
        }
      }
    }

    .thanks {
      visibility: hidden;
      font-family: $travelPlanerSubtitleFont;

      &.visible {
        visibility: visible;
      }
    }
  }

  .feedbackPopin {
    position: fixed;
    background: $black25;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: flex-end;
    z-index: 10000;
    display: flex;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
    transition: opacity linear 150ms;

    .feedbackIframe {
      position: relative;
      height: 80%;
      width: 100%;
      overflow-y: hidden;
      background: $white;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    .close {
      position: absolute;
      top: calc(20% + 10px);
      right: 10px;
      height: 30px;
      width: 30px;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .night-trip-info {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 1000;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    padding: 16px;

    .close-button {
      position: absolute;
      top: 8px;
      right: 8px;
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;

      svg {
        width: 10px;
        height: 10px;
        fill: #888;
        transition: fill 0.2s ease;

        &:hover {
          fill: #555;
        }
      }
    }

    .locations-container {
      position: relative;
      max-height: 300px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
        background: transparent;
        border-radius: 4px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 12px;
        bottom: 12px;
        left: 12px;
        width: 1px;
        background-color: $travelPlanerPrimaryColor;
        opacity: 0.3;
        z-index: 0;
      }
    }

    .location-item {
      display: flex;
      padding: 6px 10px 6px 0;
      position: relative;

      .location-marker {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $travelPlanerPrimaryColor;
        color: $white;
        font-weight: 500;
        margin-right: 12px;
        font-size: 12px;
        flex-shrink: 0;
        position: relative;
        z-index: 1;
      }

      .location-content {
        flex: 1;
        display: flex;
        flex-direction: column;

        .location-name {
          font-weight: 500;
          font-size: 14px;
          color: $travelPlanerPrimaryColor;
          margin-bottom: 2px;
        }

        .location-city {
          font-size: 12px;
          color: $travelPlanerPrimaryColor;
          margin-bottom: 8px;
        }

        .travel-duration {
          display: flex;
          align-items: center;
          padding: 4px 8px;
          background-color: #f5f5f5;
          border-radius: 12px;
          margin-top: 4px;
          margin-bottom: 8px;
          width: fit-content;

          svg {
            width: 14px;
            height: 14px;
            margin-right: 6px;
            fill: #666;
          }

          span {
            font-size: 12px;
            color: #666;
          }
        }
      }
    }
  }

  .reopen-night-info {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 1000;
    background-color: var(--travelPlanerPrimaryColor, #922373);
    color: white;
    border: none;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease;

    &:hover {
      background-color: var(--colorSecondaryBrandDarker, #66124f);
    }

    svg {
      width: 16px;
      height: 16px;
      fill: white;
    }
  }

  @media (max-width: 768px) {
    .night-trip-info {
      max-width: 240px;
      padding: 14px;
    }
  }
}

@media screen and (max-width: 768px) {
  .travelPlanerResultsNewUI {
    .travelPlanerResultsContent {
      .travelCustomMap {
        .travelPlanerMap {
          &__mapButtons {
            position: absolute;
            bottom: 0;
            left: 0;
            width: auto;
            margin: 10px;
          }
        }
        .directionProfileSelect {
          padding: 0;
        }
      }

      .travelDays {
        .aiMention {
          align-self: flex-start;
          margin-top: 24px;
          margin-left: 24px;
        }

        .fullScreenTravelPoint {
          .content {
            .contentHeader {
              margin: 4px 0;

              .titleContainer {
                margin: 4px 0 8px 0;

                .title {
                  padding-bottom: 0;

                  svg:first-child {
                    margin-bottom: auto;
                  }
                }
              }

              .separation {
                position: absolute;
                bottom: 0;
                width: 100%;
                left: 20px;
                margin: 0;
              }
            }
          }
        }
      }

      .sitesExplorator {
        .footer {
          grid-area: 3/1/3/1;
          position: sticky;
          bottom: 0;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          height: auto;
          width: 100%;
          padding: 12px 24px;
          box-shadow: 0 -3px 15px rgba(0, 0, 0, 0.2);

          .showMap {
            width: 100%;
            padding: 10px 24px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            background: $travelPlanerButtonBackground;

            svg {
              height: auto;
              width: 12px;
              margin-right: 4px;

              path,
              circle {
                fill: $travelPlanerButtonColor;
              }
            }

            span {
              color: $travelPlanerButtonColor;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
        .backButton {
          position: relative;
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 24px;
          font-weight: 500;
          font-size: 14px;
          color: var(--travelPlanerSecondaryColor);
          cursor: pointer;
          box-shadow: none;

          svg {
            transform: rotate(270deg);

            path {
              fill: var(--travelPlanerSecondaryColor);
            }
          }
        }
        .container {
          padding: 0 24px 24px 24px;

          .controls {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .title {
              font-size: 28px;
            }

            .inputBlock {
              margin: 0;
            }

            .hideAndSort {
              margin-top: 24px;
              flex-direction: column;
              align-items: flex-end;
              gap: 24px;
            }
          }

          .filtersContainer {
            position: relative;
            height: 46px;

            .filters {
              position: absolute;
              left: -24px;
              top: 0;
              max-width: calc(100vw - 8px);
              overflow: auto;
              display: flex;
              align-items: center;
              flex-wrap: nowrap;
              padding: 8px 24px 0 24px;
            }
          }

          .results {
            grid-template-columns: repeat(auto-fill, minmax(322px, 1fr));

            .site {
              flex-direction: row;
              justify-content: space-between;
              gap: 12px;
              padding: 8px 0;

              &.focused {
                padding: 8px;
              }

              .imageWrapper {
                width: 130px;
                height: 80px;

                img {
                  width: 130px;
                  height: 80px;
                }
              }

              .bodyContainer {
                flex: 1;
                height: 100%;
                padding: 0 4px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .description {
                  display: none;
                }

                .name {
                  margin: 0;
                  display: flex;
                  flex-flow: row wrap;
                  align-items: center;
                  gap: 4px;
                  margin-bottom: 4px;

                  span {
                    font-size: 0.9rem;
                  }
                }

                .selectDate {
                  padding: 8px 12px;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
    .backButton {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 24px;
      font-weight: 500;
      font-size: 14px;
      color: var(--travelPlanerSecondaryColor);
      cursor: pointer;

      svg {
        transform: rotate(270deg);

        path {
          fill: var(--travelPlanerSecondaryColor);
        }
      }
    }

    .backButtonHome {
      padding: 8px;
      svg {
        transform: rotate(0deg);

        path {
          fill: none;
        }
      }
    }

    .travelPlanerResultsContent.showMobileMap {
      .travelDays,
      .travelDaysNight,
      .days,
      .daysFooter,
      .content-wrapper,
      .sitesExplorator {
        display: none;
      }
    }
    .travelPlanerResultsContent.showMobileDays {
      .travelCustomMap {
        display: none;
      }
    }
  }
}

.travelPlanerSummary {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-weight: 200;
  font-style: italic;

  div {
    font-size: 12px;

    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
  div span:first-child {
    font-weight: 500;
    padding-left: 2px;
  }
  div span:last-child {
    font-weight: 200;
  }
  span {
    margin: 0 2px;
  }
  div::after {
    content: ' -';
  }
  div:last-child::after {
    content: '';
  }
}

.Articles {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  .articles-header {
    padding: 0 24px 12px 24px;
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      color: $travelPlanerSecondaryColor;
      font-family: $travelPlanerTitleFont;
      font-size: 22px;
      font-weight: 700;
      line-height: 32px;
    }

    svg path {
      fill: $travelPlanerSecondaryColor;
    }
  }

  .carousel {
    padding: 0 10px;
    margin-bottom: 10px;

    a {
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 0.8;
      }
    }

    .title {
      color: $travelPlanerPrimaryColor;
      font-size: 1.3rem;
      white-space: normal;
    }

    .title,
    img {
      transition: opacity 0.2s ease-in-out;
    }

    .pageButton.next {
      right: 0;
    }
    .pageButton.previous {
      left: 0;
    }

    .pageButton {
      bottom: 15%;
      background: $travelPlanerSecondaryColor;
      opacity: 0.9;
      transition: opacity 0.3s ease;

      svg path {
        stroke: white;
      }

      &:hover {
        opacity: 1;
      }
    }

    img {
      height: 250px;
    }

    .header {
      display: none;
    }

    .starNote {
      display: none;
    }

    .scrollpart {
      width: 100%;
    }
  }
}

.marker-popup {
  padding: 10px;
  max-width: 250px;

  h3 {
    margin: 0 0 8px;
    font-size: 16px;
    font-weight: bold;
    gap: 8px;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
  }

  .category {
    color: #666;
    font-size: 14px;
    margin: 4px 0;
  }

  .description {
    font-size: 14px;
    line-height: 1.4;
    margin: 8px 0;
  }

  .view-details {
    background-color: #322d80;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-top: 8px;

    &:hover {
      background-color: darken(#322d80, 10%);
    }
  }
}

// Override leaflet popup styles
.leaflet-popup-content-wrapper {
  border-radius: 8px;
}

.leaflet-popup-content {
  margin: 0;
  width: 250px !important;
}

.displayedCategory {
  font-weight: 400;
  color: black;
  font-size: 0.85rem;
  padding: 2px 6px;
  border-radius: 5px;
  white-space: nowrap;
  border: 1px solid $travelPlanerPrimaryColor;
}

/* .displayedCategory-outlined {
  background-color: $white;
  font-weight: 400;
  color: $travelPlanerPrimaryColor;
  font-size: 0.8rem;
  padding: 2px 6px;
  border-radius: 5px;
  white-space: nowrap;
  border: 1px solid $travelPlanerPrimaryColor;
} */

.leaflet-popup-content-wrapper img {
  height: 130px;
  object-fit: cover;
}

.travelPlanerResultsNewUI
  .travelPlanerResultsContent
  .EditJourney
  .editJourneyPopup
  .displayedCategory,
.travelPlanerResultsNewUI
  .travelPlanerResultsContent
  .EditJourney
  .editJourneyPopup
  .displayedCategory-outlined {
  font-size: 0.9rem;
}
