@use 'sass:map';

.travelPlaner {
  width: 100%;
  flex: 1;
  background: $white;
  color: $black;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .headerPlaceholder {
    width: 100%;
    height: 126px;
    background: $pro;
  }

  .headerImage {
    width: 100%;
    object-fit: cover;
    filter: brightness(0.5);
    height: 170px;
    object-position: bottom;
  }

  .head {
    margin: 10px auto;
    max-width: 950px;
    padding: 0 10px;
    position: relative;
    width: 100%;

    .headImage {
      height: 120px;
      object-fit: cover;
    }

    button {
      color: $black;
      padding: 8px;
      display: block;
      position: absolute;
      left: 10px;
      top: 50%;
      translate: 0 -50%;
    }
    @media screen and (min-width: 768px) {
      button {
        display: none;
      }
    }

    .progressionBar {
      margin: 25px 40px;
      display: flex;
      gap: 5px;
      width: calc(100% - 80px);
      background: $gray50;
      padding: 10px;
      border-radius: 20px;

      div {
        flex: 1;
        height: 5px;
        background: $gray100;
        border-radius: 5px;
        transition: all 0.2s linear;

        &.full {
          background: $pro;
        }
      }
    }
  }

  .travelPlanerContent {
    min-height: 500px;
    max-width: 450px;
    width: 100%;
    margin: auto;
    flex: 1;
    display: flex;
    flex-direction: column;

    &.newTravelPlanerUI {
      max-width: 900px;

      .Loader {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 66px;
        margin-bottom: 80px;
        padding: 0 20px;

        .imageSlider {
          position: relative;
          width: 100%;
          max-width: 463px;
          height: 300px;

          .image-container {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 1s ease-in-out;

            &.active {
              opacity: 1;
            }

            img {
              position: absolute;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .loader {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;
          font-family: $travelPlanerSubtitleFont;
          font-size: 18px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.18px;

          .progressBar {
            position: relative;
            flex: 1;
            height: 2px;
            background: $gray100;
            border-radius: 500px;

            .progress {
              position: absolute;
              left: 0;
              top: 50%;
              translate: 0 -50%;
              height: 100%;
              background: $travelPlanerPrimaryColor;
              border-radius: 500px;
              transition: all 0.25s linear;
              max-width: 100%;
            }
          }
        }

        .sentences0,
        .sentences1 {
          position: absolute;
          display: flex;
          align-items: center;
          font-family: $travelPlanerTitleFont;
          font-size: 32px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.48px;
          color: $gray100;
          white-space: nowrap;
          top: 405px;

          .separator {
            margin: 0 16px;
          }
        }

        .sentences0 {
          animation: scrollingLeft1 20s linear infinite;
        }

        .sentences1 {
          animation: scrollingLeft2 20s linear infinite;
        }

        @keyframes scrollingLeft1 {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-100%);
          }
        }

        @keyframes scrollingLeft2 {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(0);
          }
        }

        .sentence {
          padding: 0 24px;
          color: $travelPlanerPrimaryColor;
          font-family: $travelPlanerSubtitleFont;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }

        @media screen and (min-width: 768px) {
          margin-bottom: 130px;

          .sentences0,
          .sentences1 {
            font-size: 48px;
          }
        }

        @media screen and (min-width: 1024px) {
          margin-bottom: 130px;
        }
      }
    }

    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .search {
      .inputBlock {
        position: relative;
        margin: 8px 0;
        width: 400px;
        max-width: 100%;

        input {
          background: $white;
          padding: 12px 20px 12px 30px;
          border-radius: 20px;
          color: $black;
          width: 100%;
          border: solid 1px $black;
          height: 41px;
        }

        svg {
          position: absolute;
          left: 10px;
          top: 11px;
        }
      }
      .predictions {
        color: $black;
        z-index: 1;
        border-radius: 0 0 5px 5px;
        padding: 0 16px;
        z-index: 1000;

        button {
          padding: 7px 5px;
          opacity: 0.7;
          width: 100%;
          text-align: left;
          border-top: solid 1px $gray300;
          display: flex;
          align-items: center;
          gap: 20px;
          justify-content: space-between;

          &:first-child {
            border-top: none;
          }

          svg {
            position: initial;
            top: initial;
            left: initial;
            margin-right: 5px;
            width: 10px;
            min-width: 10px;
            height: 14px;
            min-height: 14px;

            path {
              fill: $gray300;
            }
          }

          &:hover,
          &:focus {
            opacity: 1;
            background: $gray100;

            svg path {
              fill: $pro;
            }
          }
        }

        .prediction {
          display: flex;
          align-items: center;
        }
      }
      .tag {
        display: flex;
        background: red;
        border-radius: 30px;
        padding: 4px 8px;
        align-items: center;
        font-weight: bold;
        font-size: 12px;

        svg {
          min-height: 12px;
          min-width: 12px;
        }

        @each $type, $content in $searchColors {
          &.#{$type} {
            background: map.get($content, background);
            color: map.get($content, color);

            svg path {
              fill: map.get($content, color);
            }
          }
        }
      }
      .favorite {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;
        margin: 16px 0;

        & > div {
          text-align: left;
          width: 100%;
        }

        button {
          width: calc(50% - 8px);
          max-width: 200px;
          border-radius: 10px;
          overflow: hidden;
          position: relative;

          img {
            filter: brightness(0.7);
            object-fit: cover;
            height: 100%;
          }

          .label {
            color: $white;
            position: absolute;
            bottom: 10px;
            left: 10px;
          }
          .tag {
            position: absolute;
            top: 10px;
            left: 10px;
          }
        }
      }
    }
    .searchMap {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      padding: 0 20px;
      width: 100%;
      max-width: 500px;
      margin: 0 auto;

      .mobileMap {
        display: none;
      }

      .map {
        height: 100%;
        width: 100%;
        margin: 30px 0;

        svg {
          width: 100%;

          .selectable-path {
            fill: $travelPlanerSearchMapBackground;
            cursor: pointer;
            transition: fill 0.2s ease-in-out;

            &:hover,
            &.active {
              fill: $travelPlanerSearchMapActiveBackground !important;
            }

            &.inactive {
              fill: $travelPlanerSearchMapInactiveBackground;
            }
          }
        }
      }

      button {
        margin-top: auto;
        border-radius: 10px;
        background: $travelPlanerPrimaryColor;
        color: $white;
        padding: 12px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.14px;
        transition: all 0.2s ease-in-out;

        &:disabled {
          background: $gray100;
        }

        path {
          stroke: $white;
        }
      }

      @media screen and (min-width: 768px) {
        gap: 44px;

        .layoutTitle {
          font-size: 40px;
          line-height: 1.375;
          letter-spacing: 0.4px;
        }

        .layoutSubtitle {
          font-size: 18px;
          letter-spacing: 0.18px;
        }
      }
    }
    .filterLayout {
      margin: 0 15px;

      &.newUI {
        width: calc(100% - 30px);
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 45px;

        .layoutTitle {
          color: $travelPlanerPrimaryColor;
          font-family: $travelPlanerTitleFont;
          font-size: 25px;
          line-height: 1.57;
          letter-spacing: 0.35px;
          margin-bottom: 0;
        }

        .layoutSubtitle {
          color: $travelPlanerSecondaryColor;
          font-family: $travelPlanerSubtitleFont;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.105px;
          margin-top: 0 !important;
        }

        .layoutButton {
          position: fixed;
          bottom: 0;
          left: 0;
          margin-top: auto;

          button {
            border-radius: 10px;
            background: $travelPlanerPrimaryColor;
            border: 1px solid;
            border-color: $travelPlanerPrimaryColor !important;
            padding: 12px 20px;
            display: flex;
            align-items: center;
            gap: 12px;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.14px;
            transition: all 0.2s ease-in-out;

            &:hover {
              &.back {
                background: $travelPlanerPrimaryColor;
                color: $white;
              }

              &.next {
                background: $white;
                color: $travelPlanerPrimaryColor;
              }
            }

            &:disabled {
              opacity: 0.5;

              &:hover {
                background: $travelPlanerPrimaryColor;
                color: $white;
                cursor: default;
              }
            }

            span {
              margin-bottom: -2px;
            }

            svg {
              position: absolute;
              top: 50%;
              translate: 0 -50%;
            }

            &.back {
              color: $travelPlanerPrimaryColor;
              background: $white;

              svg {
                left: 16px;
              }
            }

            &.next {
              svg {
                right: 16px;

                path {
                  stroke: $white;
                }
              }
              &:hover {
                svg {
                  path {
                    stroke: $travelPlanerPrimaryColor;
                  }
                }
              }
            }
          }
        }

        & > div:nth-child(2) {
          margin-top: 30px;
        }
        .partner-select {
          width: 300px;
        }
      .select-icon{
        padding: 10px;
      }

        .numberInput {
          max-width: 415px;
        }

        .slide {
          font-family: $travelPlanerSubtitleFont;
          line-height: 20px;
          letter-spacing: 0.01em;
          max-width: 493px;

          .day {
            font-size: 14px;
            font-weight: 600;
          }

          .hours {
            font-size: 12px;
            font-weight: 300;
            margin: 0 0 20px;
          }
        }

        .react-calendar {
          font-family: $travelPlanerSubtitleFont;
          max-width: 100%;
          width: 100%;

          .react-calendar__navigation {
            max-width: 258px;
            margin: 0 auto 4px auto;

            button {
              svg {
                margin: 0 auto;
              }
            }
          }

          .react-calendar__viewContainer {
            max-width: 332px;
            margin: 0 auto;
          }

          abbr,
          span {
            color: $travelPlanerSecondaryColor;
          }

          .react-calendar__month-view__weekdays__weekday {
            abbr {
              color: $travelPlanerPrimaryColor;
            }
          }

          button {
            font-family: $travelPlanerSubtitleFont;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            border-radius: 10px;

            &:disabled {
              abbr {
                color: $gray100;
              }
            }
          }
          .react-calendar__tile {
            margin: 20px 0;
            padding: 4px 18px;

            &.react-calendar__month-view__days__day {
              margin: 4px 0;
              width: 38px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .react-calendar__tile--now {
            &:hover {
              background: #e6e6e6;
            }
          }

          .react-calendar__tile:disabled,
          .react-calendar__navigation button:disabled {
            background: none;
            color: $gray100;
          }

          .react-calendar__tile--active:enabled,
          .react-calendar__tile--active:enabled:hover,
          .react-calendar__tile--active:enabled:focus {
            background: $travelPlanerPrimaryColor;
            color: $white;
            border-radius: 10px;

            abbr,
            span {
              color: $white;
            }
          }
        }

        .buttonListComposition {
          row-gap: 10px;
          column-gap: 16px;
          justify-content: center;
          width: 100%;
          max-width: 500px;
          margin-bottom: 80px;

          button {
            position: relative;
            height: 155px;
            width: 100%;
            min-width: 200px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            span {
              position: absolute;
              left: 6px;
              top: 6px;
              border-radius: 6px;
              background: $travelPlanerPrimaryColor;
              color: $white;
              padding: 0 8px;
              font-family: $travelPlanerSubtitleFont;
              font-weight: 500;
              font-size: 10px;
              line-height: 20px;
              letter-spacing: 0.1px;
            }

            &.selected {
              border: 1px solid $travelPlanerPrimaryColor;
              border-bottom: 5px solid $travelPlanerPrimaryColor;
            }
          }
        }

        .interestPoints {
          display: flex;
          align-items: center;
          justify-content: center;
          row-gap: 26px;
          column-gap: 14px;
          flex-wrap: wrap;
          margin-bottom: 84px;
          max-width: 500px;

          .point {
            max-width: 200px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;

            .label {
              white-space: nowrap;
              font-family: $travelPlanerSubtitleFont;
              color: $travelPlanerSecondaryColor;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 0.14px;
            }

            .buttons {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;

              button {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-family: $travelPlanerSubtitleFont;
                color: $travelPlanerPrimaryColor;
                font-size: 10px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.1px;

                svg {
                  circle {
                    stroke: $travelPlanerPrimaryColor;
                  }
                  path {
                    fill: $travelPlanerPrimaryColor;
                  }
                }

                &:hover,
                &.selected {
                  svg {
                    circle {
                      fill: $travelPlanerPrimaryColor;
                    }
                    path {
                      fill: $white;
                    }
                  }
                }
              }
            }

            .image-wrapper {
              position: relative;

              img {
                border-radius: 10px;
                height: 155px;
                max-width: 200px;
                object-fit: cover;

                &.like {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }

        .buttonListBudget {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          margin-bottom: 84px;

          button {
            width: 100%;
            max-width: 178px;
            height: 155px;
            padding: 10px;
            background: none;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: space-between;

            &:hover {
              background: $gray100;
            }

            &.selected {
              background: $gray100;
              border: none;
            }

            svg {
              width: auto;
              height: auto;

              path {
                fill: $travelPlanerPrimaryColor;
                stroke: none;
              }
            }

            span {
              font-family: $travelPlanerSubtitleFont;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 0.14px;
            }
          }
        }

        @media screen and (min-width: 768px) {
          .layoutButton {
            button {
              padding: 12px 120px;
            }
          }

          & > div:nth-child(2) {
            margin-top: 64px;
          }
        }
      }

      .layoutTitle {
        font-weight: 450;
        font-size: 18px;
        line-height: 22px;
        text-align: left;
        margin-bottom: 15px;
      }

      .layoutSubtitle {
        color: $travelPlanerSecondaryColor;
        font-family: $travelPlanerSubtitleFont;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.105px;
        margin-top: 0 !important;
        margin-bottom: 15px;
      }

      .layoutButton {
        position: relative;
        width: 100%;
        background: $white;
        padding: 15px;
        z-index: 1;
        display: flex;
        justify-content: center;
        margin-top: 50px;
        gap: 10px;

        button {
          width: 100%;
          svg path {
            stroke: currentColor;
          }
          &:hover {
            svg path {
              fill: none;
            }
          }

          &.white {
            border-color: $pro;
            color: $pro;
            background: $white;
            &:hover {
              border-color: $white;
              color: $white;
              background: $pro;
            }
          }
        }
        .back {
          display: none;
        }
        @media screen and (min-width: 768px) {
          button {
            width: 200px;
          }
          .back {
            display: flex;
          }
        }
      }
    }
    .composition,
    .budget {
      .buttonList {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        button {
          background: $gray100;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100px;
          max-width: 200px;

          svg {
            height: 40px;

            path {
              stroke: currentColor;
            }
            circle {
              stroke: currentColor;
            }
          }

          .selectedImage {
            position: absolute;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
          }

          &.selected {
            border: solid 1px $pro;
            border-bottom: solid 5px $pro;
            color: $pro;

            .selectedImage {
              opacity: 1;
            }
          }
        }
      }
    }
    .times {
      .buttonList {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 12px;

        button {
          padding: 6px 24px;
          border-radius: 200px;
          background: #ffffff;
          border: 1px solid $travelPlanerPrimaryColor;
          color: $travelPlanerPrimaryColor;
          height: auto;
          font-family: $travelPlanerTitleFont;
          font-size: 16px;
          line-height: 32px;
          max-width: none;

          &.selected {
            background: $travelPlanerPrimaryColor;
            color: $white;
          }
        }
      }
    }
    .composition .buttonList button {
      width: calc(50% - 4px);
    }
    .budget .buttonList button {
      width: calc(33% - 5px);
    }
    .hoursSliders,
    .interests {
      .rc-slider-track {
        color: var(--travelPlanerPrimaryColor, $pro);
        background: var(--travelPlanerPrimaryColor, $pro);
        border: $pro;
      }

      .rc-slider-rail {
        background: $gray100;
      }
      .sur-handle {
        position: absolute;
        z-index: 1;
        width: 32px;
        height: 32px;
        padding: 9px;
        margin-top: -14px;
        border-radius: 50%;
        cursor: pointer;
        cursor: grab;
        touch-action: pan-x;
        .handle {
          background: var(--travelPlanerPrimaryColor, $pro);
          height: 100%;
          width: 100%;
          border-radius: 50%;
        }
      }
    }
    .hoursSliders {
      .slide {
        width: 100%;
        margin-top: 20px;
        .day {
          font-size: 16px;
          font-weight: 400;
          color: var(--travelPlanerSecondaryColor, $pro);
        }
        .hours {
          font-size: 14px;
          font-weight: 325;
          color: var(--travelPlanerPrimaryColor, $gray500);
          margin: 5px 0 20px;
        }
      }
    }
    .startDate {
      .react-calendar {
        border: none;
        font-family: $mainFont;
        margin: auto;
        width: calc(100% - 32px);
        max-width: 340px;

        button {
          font-family: $mainFont;
        }
        .react-calendar__tile--now {
          background: none;
        }
        .react-calendar__tile:disabled,
        .react-calendar__navigation button:disabled {
          background: none;
          color: $gray100;
        }
        .react-calendar__tile--active:enabled,
        .react-calendar__tile--active:enabled:hover,
        .react-calendar__tile--active:enabled:focus {
          background: $pro;
          color: $white;
          border-radius: 20px;
        }
      }
    }
    .interests {
      .slide {
        margin: 20px 0;
        .legend {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          div:first-child {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
          }
          div:last-child {
            font-size: 14px;
            font-weight: 325;
            color: $gray500;
          }
        }
      }
    }
    .loading {
      margin: 40px auto;
      width: 200px;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;

      div {
        position: absolute;
        font-size: 42px;
        color: $pro;
        top: 75px;
        span {
          font-size: 0.7em;
        }
      }
    }
  }

  .error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin: 0 32px;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    .headerPlaceholder {
      height: 158px;
    }

    .head {
      .headImage {
        height: 250px;
        border-radius: 12px;
      }
    }
  }
}

.propulsedBy {
  display: flex;
  align-items: center;
  font-family: Guilroy, Sans, sans-serif;

  img {
    width: 100px;
    margin-left: 4px;
    margin-bottom: 6px;
  }
}

.travelPlaner .headerImage {
  display: none;
}

.travelPlaner .head {
  margin-top: 95px;
}
