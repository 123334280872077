.TravelNightTags {
  $mobileGap: 20px;
  $desktopGap: 15px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $mobileGap;

  .TravelNightTag {
    display: flex;
    align-items: center;
    gap: 20px;

    svg {
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px;
    }

    .TravelNightTagContent {
      display: flex;
      flex-direction: column;
      gap: 3px;
      flex: 1;
      font-family: $mainFont;

      h4 {
        font-size: 16px;
        line-height: 100%;
        font-weight: 700;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }
    }
  }

  @media screen and (min-width: 768px) {
    gap: $desktopGap;

    .TravelNightTag {
      .TravelNightTagContent {
        h4 {
          line-height: 22px;
        }

        p {
          line-height: 100%;
          font-weight: 400;
        }
      }
    }
  }
}
