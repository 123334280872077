.NightDayHeader {
  $mobilePadding: 24px;
  $mobileGap: 24px;
  $desktopPadding: 24px 48px;
  $desktopGap: 48px;

  padding: $mobilePadding;
  display: flex;
  flex-direction: column;
  gap: $mobileGap;

  .ButtonV2 {
    padding: 8px 0;
    gap: 10px;

    .ButtonV2__text {
      font-family: $travelPlanerSubtitleFont;
      font-weight: 800;
      font-size: 14px;
      line-height: 24px;
      color: $travelPlanerPrimaryColor;
    }

    &:hover {
      svg {
        animation: pulser 1s infinite;

        @keyframes pulser {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.1);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }

    svg {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;

      path {
        fill: $travelPlanerPrimaryColor;
        stroke: $travelPlanerPrimaryColor;
      }
    }
  }

  .NightDayHeaderTop {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: left;
    white-space: nowrap;

    .NightDayAiMention {
      display: flex;
      align-items: center;
      gap: 4px;
      align-self: flex-end;

      svg {
        width: 16px;
        height: 16px;
        background-color: $travelPlanerPrimaryColor;
        border-radius: 100px;
        padding: 2px;

        path {
          fill: white;
        }
      }

      span {
        font-family: $travelPlanerSubtitleFont;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0%;
        color: $travelPlanerPrimaryColor;
      }
    }

    .NightDayHeaderButtons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 6px;
      flex-wrap: wrap;

      .EditJourney {
        .EditJourneyButton {
          svg {
            transition: rotate 0.3s ease-in-out;
          }

          &:hover {
            svg {
              rotate: 180deg;
            }
          }

          svg {
            path {
              fill: $travelPlanerPrimaryColor;
              stroke: $travelPlanerPrimaryColor;
            }
          }
        }
      }
    }
  }

  .NightDayHeaderDay {
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: $mainFont;
    font-size: 26px;
    font-weight: 600;
    color: $travelPlanerPrimaryColor;
  }

  @media screen and (min-width: 768px) {
    padding: $desktopPadding;
    gap: $desktopGap;

    .ButtonV2 {
      padding: 8px 10px;

      svg {
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
      }
    }

    .NightDayHeaderTop {
      flex-direction: row-reverse;
      justify-content: space-between;
      flex-wrap: wrap;

      .NightDayAiMention {
        margin-right: 34px;
        align-self: center;
        gap: 10px;
      }

      .NightDayHeaderButtons {
        justify-content: flex-start;
      }

      .NightDayHeaderDay {
        font-size: 25px;
        font-weight: 700;
      }
    }
  }
}
