.TravelNightPoint {
  $mobilePadding: 12px 24px 24px 24px;
  $desktopPadding: 20px 48px 48px 48px;
  $mobileGap: 24px;
  $desktopGap: 24px;

  /*
  * Common Styles
  */
  * {
    font-family: $travelPlanerSubtitleFont;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0px;
    color: $travelPlanerPrimaryColor;
  }

  p {
    line-height: 24px;
  }

  strong {
    font-weight: 700;
  }

  .ButtonV2 {
    background: $travelPlanerPrimaryColor;


    border-radius: 4px;

    &.huttopia {
      background-color: var(--type-color-green);
      border: none;

      .ButtonV2__text {
        color: white
      }
    }

    &.onlycamp {
      background-color: var(--type-color-yellow);
      border: none;

      .ButtonV2__text {
        color: var(--type-color-green);
      }
    }

    &.bivouac {
      background-color: var(--type-color-green-light);
      border: none;

      .ButtonV2__text {
        color: white
      }
    }


    .ButtonV2__text {
      font-size: 16px;
      line-height: 24px;
      font-weight: 800;
      color: white;

    }
  }

  /*
  * End of Common Styles
  */

  .TravelNightPointContainer {
    padding: $mobilePadding;
    display: flex;
    flex-direction: column;
    gap: $mobileGap;

    .TravelNightPointHeaderContainer {
      .TravelNightPointHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        flex-wrap: wrap;

        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0px;
        }

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          padding: 7px 10px;
          border-radius: 8px;
          &.huttopia {
            background-color: var(--type-color-green);
              color: white
          }
          &.onlycamp {
            background-color: var(--type-color-yellow);
            color: var(--type-color-green);
          }

          &.bivouac {
            background-color: var(--type-color-green-light);
            color: white
            
          }
        }
      }

      .TravelNightPointSubHeader {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 30px;
      }

      .TravelNightPointCheckAvailabilityButton {
        margin-top: 6px;
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 768px) {

    /*
    * Common Styles
    */
    h3 {
      font-size: 20px;
      line-height: 22px;
    }

    .TravelNightPointContainer {
      padding: $desktopPadding;
      gap: $desktopGap;

      .TravelNightPointHeaderContainer {
        .TravelNightPointSubHeader {
          margin-top: 10px;
          flex-direction: row;
          justify-content: space-between;
        }

        .TravelNightPointCheckAvailabilityButton {
          margin-top: 20px;
          width: fit-content;

        }
      }
    }
  }
}