$mobileInternalPadding: 24px;
$mobileExternalPadding: 0;
$desktopInternalPadding: 0;
$desktopExternalPadding: 48px;
$mobileGap: 6px;
$desktopGap: 10px;
$borderRadius: 10px;

.TravelNightCarousel {
  width: 100%;
  overflow: hidden;
  position: relative;

  .NightCarousel {
    padding: 0 $mobileInternalPadding;
    margin: 0 $mobileExternalPadding;
    overflow: hidden;

    &__slide {
      width: auto;
      max-width: 80%;
      flex-shrink: 0;
      height: auto;
      border-radius: $borderRadius;
      overflow: hidden;
    }
  }

  .swiper-slide {
    img {
      aspect-ratio: 267 / 148;
    }
  }

  @media (min-width: 768px) {
    .NightCarousel {
      padding: 0 $desktopInternalPadding;
      margin: 0 $desktopExternalPadding;

      .swiper-slide {
        img {
          aspect-ratio: 446 / 247;
        }
      }
    }
  }
}
